import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

export enum TwoColumnSectionStyle {
  LIGHT,
  DARK,
}

interface TwoColumnSectionTheme {
  color: string
  backgroundColor: string
}

const lightTheme = {
  color: '#000000',
  backgroundColor: '#FFFFFF',
}

const darkTheme = {
  color: '#FFFFFF',
  backgroundColor: '#232323',
}

type TwoColumnSectionProps = {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
  style?: TwoColumnSectionStyle
  topPadding?: number
  bottomPadding?: number
  alignItems?: 'center' | 'flex-start'
}

const useStyles = makeStyles(theme => ({
  twoCol: {
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justify: 'center',
    },
  },

  twoColLeftItem: {
    maxWidth: '510px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '20px',
    },
  },

  twoColRightItem: {
    maxWidth: '510px',
    [theme.breakpoints.down('sm')]: {
      margin: '60px auto 0',
      padding: '20px',
    },
  },
}))

const TwoColumnSection = ({
  leftCol,
  rightCol,
  style,
  topPadding,
  bottomPadding,
  alignItems = 'center',
}: TwoColumnSectionProps) => {
  const matcher = useMediaQuery(useTheme().breakpoints.down('sm'))
  const classes = useStyles()
  const theme = style === TwoColumnSectionStyle.DARK ? darkTheme : lightTheme
  if (matcher) {
    if (topPadding) topPadding /= 2
    if (bottomPadding) bottomPadding /= 2
  }
  return (
    <Box
      style={{
        color: theme.color,
        backgroundColor: theme.backgroundColor,
        paddingTop: topPadding ? topPadding + 'px' : '0',
        paddingBottom: bottomPadding ? bottomPadding + 'px' : '0',
      }}
    >
      <Grid
        container
        wrap="nowrap"
        direction="row"
        justify="space-between"
        alignItems={alignItems}
        className={classes.twoCol}
      >
        <Box className={classes.twoColLeftItem}>{leftCol}</Box>
        <Box className={classes.twoColRightItem}>{rightCol}</Box>
      </Grid>
    </Box>
  )
}

export default TwoColumnSection
