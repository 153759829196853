//@ts-nocheck
import { Box, makeStyles, Typography, Paper } from '@material-ui/core'
import React from 'react'
import Layout from '../../components/layout'
import ContactForm from './_contactForm'
import { navigate, graphql } from 'gatsby'
import TwoColumnSection from '../../components/two_column_section/two_column_section'

import illustration from './illustration_get_in_touch.png'

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: '40px',
    lineHeight: `calc(${theme.typography.h6.fontSize}+20px})`,
    marginBottom: '60px',
  },
  illustration: {
    width: '70%',
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: '4px',
  },
}))
const Contact = () => {
  const classes = useStyles()
  const afterSubmit = () => {
    navigate('/contact/thanks')
  }
  return (
    <Layout
      pageTitle="Get In Touch With LightTag"
      pageDescription="LightTag's Contact Page"
    >
      <TwoColumnSection
        rightCol={
          <Box>
            <Typography variant={'h6'}>Get In Touch</Typography>
            <Typography className={classes.text}>
              Need a custom solution? We understand data security and can build
              solutions to accommodate your needs.
            </Typography>
            <img className={classes.illustration} src={illustration} />
          </Box>
        }
        leftCol={
          <Paper className={classes.paper}>
            <ContactForm afterSubmit={afterSubmit} />
          </Paper>
        }
      />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    symbols: file(relativePath: { eq: "contact/symbols.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
