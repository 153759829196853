import React from 'react'
import * as EmailValidator from 'email-validator'
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { gtagSentAContactMessage } from '../../utils/analytics'
import { addEmailToMailchimp } from '../../utils/signupToMialchimp'

interface Props {
  afterSubmit: () => void
}

type TFormValues = {
  name?: string
  email?: string
  company?: string
  message?: string
}

type TErrors = TFormValues
type FormState = {
  values: TFormValues
  errors: TErrors
  valid: boolean
}

function validateFormValues(values: TFormValues): TErrors | true {
  const errors: TErrors = {}

  let valid = true
  if (!values.name || values.name.length === 0) {
    errors.name = 'Please tell us your name'
    valid = false
  }
  if (!values.message || values.message.length === 0) {
    errors.message = 'Must specify a message'
    valid = false
  }
  if (!values.email || values.email.length === 0) {
    errors.email = 'Please enter an email address'
    valid = false
  } else {
    if (!EmailValidator.validate(values.email)) {
      errors.email = "That's not a valid email."
      valid = false
    }
  }
  if (!valid) {
    return errors
  }
  return valid
}
function initialStateFactory(): FormState {
  return {
    values: {},
    errors: {},
    valid: false,
  }
}

const useStyles = makeStyles(theme => ({
  button: {},
  containedPrimary: {
    background: '#7791E6',
  },
}))

const ContactForm = (props: Props) => {
  const classes = useStyles()
  const [formState, setFormState] = React.useState<FormState>(
    initialStateFactory()
  )
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const handleChange = (key: keyof TFormValues, value: string) => {
    setFormState(current => {
      const newState = { ...formState }
      newState.values[key] = value
      newState.errors = {}
      return newState
    })
  }
  const keys: Array<keyof TFormValues> = ['name', 'company', 'email', 'message']
  const errors = formState.errors
  const values = formState.values
  const filled = keys.every(name => values[name] !== undefined)
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validOrErrors = validateFormValues(formState.values)
    setFormState(currentState => {
      const newState = { ...currentState }
      if (validOrErrors === true) {
        newState.valid = true
      } else {
        newState.errors = validOrErrors
      }
      return newState
    })
    if (validOrErrors === true) {
      //it's valid
      setSubmitting(true)
      fetch(process.env.CONTACT_URL as string, {
        method: 'POST',
        body: JSON.stringify(formState.values),
      }).then((response: any) => {
        setSubmitting(false)
        gtagSentAContactMessage()
        props.afterSubmit()
        return addEmailToMailchimp(formState.values.email)
      })
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        fullWidth
        variant="outlined"
        label="Name"
        InputLabelProps={{
          shrink: true,
        }}
        helperText={errors.name}
        onChange={e => handleChange('name', e.target.value)}
        error={errors.name !== undefined}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Email"
        InputLabelProps={{
          shrink: true,
        }}
        helperText={errors.email}
        onChange={e => handleChange('email', e.target.value)}
        error={errors.email !== undefined}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Company"
        InputLabelProps={{
          shrink: true,
        }}
        helperText={errors.company}
        onChange={e => handleChange('company', e.target.value)}
        error={errors.company !== undefined}
      />

      <Typography style={{ marginBottom: '20px' }}>
        How can we help you?
      </Typography>

      <TextField
        multiline
        fullWidth
        variant="outlined"
        label="Message"
        InputLabelProps={{
          shrink: true,
        }}
        type="textarea"
        helperText={errors.company}
        placeholder="Hi LightTag! We're labeling lots of data and would like to see if your tool can help."
        onChange={e => handleChange('message', e.target.value)}
        error={errors.message !== undefined}
        rows={5}
      />

      <Button
        classes={classes}
        variant="contained"
        type="submit"
        disabled={filled !== true}
        color="primary"
        fullWidth
        style={{ display: submitting ? 'none' : undefined }}
      >
        {submitting ? (
          <CircularProgress
            style={{ display: !submitting ? 'none' : undefined }}
          />
        ) : (
          'Send'
        )}
      </Button>
    </form>
  )
}

export default ContactForm
